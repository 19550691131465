.navbar {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  cursor: pointer;
}
