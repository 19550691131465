.fileStored {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  cursor: pointer;
  gap: 20px;
}
