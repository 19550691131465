.cronologia {
  padding: 0 30%;
}
.accessDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;
}
.access {
  padding: 10px 20px;
  border-radius: 40px;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: #000;
  text-decoration: none;
}

@media screen and (max-width: 1250px) {
  .cronologia {
    padding: 0 10%;
  }
}
