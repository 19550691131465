.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
  color: #000;
}

.dropdownHeader {
  color: #000;
  font-weight: bold;
  padding: 20px 0 5px 0;
}
.buttonsType {
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 100%;
  padding: 30px;
  box-sizing: border-box;
}
.buttonType {
  background-color: #fff;
  border-radius: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 50px;
  flex-wrap: wrap;
}
.commento {
  margin: 20px 0;
  background-color: #fff;
  color: #000;
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 100%;
  border: none;
  min-height: 50%;
  resize: none;
  min-height: 200px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  user-select: text;
  position: relative;
}
.commentoInput {
  background-color: #fff;
  color: #000;
  width: 100%;
  border: none;
  resize: none;
  min-height: 200px;
  border-radius: 12px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  user-select: text;
  transition: all 0.2s ease-in-out;
}
.commentoInput:focus {
  outline: none;
  background-color: #f8f8f8;
  padding: 10px;
  box-sizing: border-box;
}
.buttonCaricaFile {
  background-color: #fff;
  color: #000;
  border-radius: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 50px;
  flex-wrap: wrap;
  margin: 20px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-weight: 700;
  text-decoration: none;
}
.carica {
  background-color: #2196f3;
  color: #fff;
  border-radius: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 50px;
  flex-wrap: wrap;
  margin: 20px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-weight: 700;
  text-decoration: none;
}
.disabilitato {
  background-color: #f8f8f8;
  color: #000;
  border-radius: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px 50px;
  flex-wrap: wrap;
  margin: 20px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: larger;
  font-weight: 700;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .report {
    padding: 0 20%;
  }
}
