.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.innerModal {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 1001;
}
.innerModalAllegati {
  height: 90%;
  width: 98%;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: white;
}
.headerModal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}
.headerAlertModal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;
}
.headerSuccessAlertModal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.successAlertModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.textAlertModal {
  padding: 30px 30px 30px 0;
  min-width: fit-content;
}
.titleModal {
  font-size: 20px;
  font-weight: 700;
}
.targaModal {
  font-size: 20px;
  font-weight: 400;
  margin-left: 10px;
  color: #a3a3a3;
}
.chat {
  overflow-wrap: break-word;
}
.annulla {
  width: 100%;
  padding: 10px 0;
  border-radius: 50px;
  text-align: center;
  margin: 10px 0;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  box-sizing: border-box;
  border: none;
}
.confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background-color: #42a5f5;
  border-radius: 50px;
  color: #fff;
  text-align: center;
  margin: 10px 0;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
}
.exit {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.dropZone {
  border-radius: 12px;
  border: 2px dashed #005192;
  min-height: 40vh;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.arrowBack {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;
  padding: 10px;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.arrowNext {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50px;
  padding: 10px;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.responsiveImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
