.card {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 20px 0;
  cursor: pointer;
  overflow: hidden;
}
.headline {
  display: flex;
  justify-content: space-between;
}
.headlineLeft {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 20px;
  flex-wrap: wrap;
  max-width: 250px;
}
.cardTitle:first-of-type {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
}
.autista {
  font-size: 18px;
  font-weight: 500;
}
.headlineRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
}
.type {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: fit-content;
}
.multa {
  font-size: 14px;
  font-weight: 500;
  /* color: #824b52; */
  line-height: 0px;
  background-color: #e9cbcf;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  padding: 3px 8px;
  box-sizing: border-box;
  margin-top: 4px;
  gap: 5px;
  min-width: 85px;
  min-height: 26px;
}
.sinistro {
  font-size: 14px;
  font-weight: 500;
  /* color: #44666e; */
  background-color: #c8dce0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  padding: 3px 8px;
  box-sizing: border-box;
  margin-top: 4px;
  gap: 5px;
  min-width: 85px;
  min-height: 26px;
}

.targa {
  margin-top: 4px;
  font-size: 15px;
  font-weight: 400;
}

.commentoShow {
  color: #a3a3a3;
  margin-top: 6px;
  font-size: 15px;
  font-weight: 400;
  overflow-wrap: break-word;
}
.stato {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.mod {
  background-color: #eeeeee;
  border-radius: 8px;
  font-size: small;
  padding: 4px 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  min-width: 85px;
  min-height: 26px;
}
.completed {
  color: #fff;
  width: 100%;
  margin-top: 20px;
  background: #bbdefb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 400;
  cursor: not-allowed;
}
.buttonGrid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
}
.nextStepButton {
  background-color: #42a5f5;
  color: #fff;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}
