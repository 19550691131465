.balls {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.ball1,
.ball2,
.ball3 {
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background-color: #fff;
  animation: bounce 0.5s infinite alternate;
  background-color: #2196f3;
}

.ball2 {
  animation-delay: 0.1s;
}
.ball1 {
  animation-delay: 0.2s;
}
.ball3 {
  animation-delay: 0.3s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
  }
}
